@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

html {
    scroll-behavior: smooth;
}
body {
    background-image: url('../src/assets/img/background-wall.png');
}

.hero {
    width: 100%;
    height: calc(100vh - 104px);
}

.video-section, .clients {
    height: 100vh;
}

@media (max-width: 768px) {
    .video-section {
        height: 160vh;
    }

    .clients {
        height: 120vh;
    }
}

.service {
    height: 95vh;
}

.service .group:hover {
    background: whitesmoke;
}

/*.slick-next, .slick-prev {*/
/*    display: none!important;*/
/*}*/


.animated-1, 
.animated-2,
.animated-3,
.animated-4 {
    width: 100%;
    height: 100%;
}

.animated-2 {
    transform: translate(-50%, 0);
}

.animated-3 {
    transform: translate(0, -50%);
}

.animated-4 {
    transform: translate(-50%, -50%);
}